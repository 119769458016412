// copy of templates/index-page.js

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Link, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { purple, red } from '@mui/material/colors';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import Layout from '../components/Layout';
import Features from '../components/Features';
import BlogRoll from '../components/BlogRoll';
import FullWidthImage from '../components/FullWidthImage';
import RightHandImage from '../components/RightHandImage';
import Sidebar from '../components/Sidebar';
import Content, { HTMLContent } from '../components/Content';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';

import logo from '../img/logo.svg';
import HeroSection from '../components/HeroSection';
import AboutSection from '../components/AboutSection';
import Mantra from '../components/sections/ALEiGN-mantra';
import NOOBIntro from '../components/sections/NOOB-Intro';
import ImageNavQuilt from '../components/sections/ImageNavQuilt';
import UpdateList from '../components/UpdateList';
// import ProductList from '../components/ProductList';

import Productive from '../img/being-productive.mp4';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	zIndex: 1,
});

const MainContent = styled(Box)({
	// marginTop: 'auto',
	// marginBottom: '50px',
	margin: 'auto 64px 50px',
});

const FeatureOne = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: theme.palette.paper,
	padding: theme.spacing(12),
}));

const CardOne = styled(Box)(({ theme, props }) => ({
	display: 'flex',
}));

const ScrollAnchor = styled(Box)({
	position: 'absolute',
	top: '-106px',
});

// const Purple = purple[200];

// eslint-disable-next-line
export const ALEiGNLandingTemplate = ({
	image,
	title,
	heading,
	subheading,
	mainpitch,
	description,
	intro,
	content,
	contentComponent,
}) => {
	const heroImage = getImage(image) || image;
	const PageContent = contentComponent || Content;

	return (
		<>
			<HeroSection />
			<ImageNavQuilt />
			<AboutSection />
			<Mantra />
			<NOOBIntro />
		</>
	);
};

ALEiGNLandingTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	mainpitch: PropTypes.object,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array,
	}),
};

const ALEiGNLanding = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<ALEiGNLandingTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				subheading={frontmatter.subheading}
				mainpitch={frontmatter.mainpitch}
				description={frontmatter.description}
				intro={frontmatter.intro}
				contentComponent={HTMLContent}
				content={post.html}
			/>
		</Layout>
	);
};

ALEiGNLanding.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default ALEiGNLanding;

export const pageQuery = graphql`
	query open4bizTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "ALEiGN-landing" } }) {
			html
			frontmatter {
				title
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				heading
				subheading
				mainpitch {
					title
					description
				}
				description
				intro {
					blurbs {
						text
						heading
					}
					heading
					description
				}
			}
		}
	}
`;
